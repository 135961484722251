<template>
  <div id="clinic" v-if="getClinic">
    <div class="card-header">
      <h1>{{ getClinic.name.fullName }}</h1>
    </div>
    <InformationCard :clinic="getClinic" />
    <Gallery :clinic="getClinic" />
    <div class="doctor-consultation-info">
      <h2>Консультация доктора включает:</h2>
      <ul>
        <li>
          Сбор анамнеза,
        </li>
        <li>
          Тщательный осмотр пациента,
        </li>

        <li>
          Изучение истории болезни,
        </li>

        <li>
          Изучение снимков МРТ, РГ, КТ,
        </li>
        <li>
          Плантоскопия,
        </li>
        <li>
          Подробное обсуждение с пациентом всех аспектов лечения,
        </li>
        <li>
          Совместное решение об операции, подбор времени.
        </li>
      </ul>
      
    </div>
    <div class="consultation-price doctor-consultation-info">
        <p>Стоимость консультации в Ростове: <span>3000₽</span></p> 
        <p>Стоимость консультации в Таганроге: <span>2500₽</span></p> 
      </div>
    <div class="doctor-consultation-info">
      <h2>Операция «под ключ» включает в себя:</h2>
      <ul>
        <li>

          Полное сопровождение (контроль подготовки, оформление документации, консультации по всем вопросам)
        </li>

        <li>

          Работа хирурга
        </li>

        <li>

          Операционная бригада
        </li>

        <li>

          Подбор анестезии
        </li>

        <li>

          Винты, импланты, спицы и т.д
        </li>


        <li>

          Отдельная палата с полным обеспечением
        </li>

        <li>

          3-х разовое питание
        </li>

        <li>

          Перевязки
        </li>

        <li>

          Мониторинг состояния пациента
        </li>

        <li>

          Обувь Барука/тутор на 6 недель
        </li>
      </ul>
      <h5 class="price">Стоимость: от 30 000 ₽</h5>
    </div>
  </div>
</template>

<script>
import InformationCard from "../components/CLINICS_InformationCard.vue";
import { mapGetters } from "vuex";
import Gallery from "../components/CLINIC_gallery.vue";

export default {
  components: {
    InformationCard,
    Gallery,
  },
  computed: {
    ...mapGetters("ModuleClinics", ["getClinic"]),
  },
};
</script>

<style lang="scss" scoped>
#clinic {
  position: relative;
  height: 100%;
  width: 100%;
}

.doctor-consultation-info {
  margin-top: 16px;
}
.price {
  font-size: 20px;
  margin-top: 16px;
  font-weight: 600;
}
.consultation-price p{
  margin-top: 4px;
  font-weight: 600;
}
.consultation-price span{
  font-weight: 500;
  color: #0115D3;
}
</style>
